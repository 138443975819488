<template>
  <div id="subjects" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="subjects" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ subjects.indexOf(props.row) + 1 }}
        </b-table-column>
        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>
        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="subjects"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SUBJECTS from '../../../graphql/subject/Subjects.gql'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'subjects',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Subjects',
      subjects: [],
      isPaginated: true,
      id: null,
    }
  },
  methods: {
    initializeInfo(subject) {
      // this.$router.push(`/school/${this.schoolId}/subject_info/${subject.id}`)
    },
    initializeUpdate(subject) {
      this.$router.push(`/school/${this.schoolId}/subject/${subject.id}`)
    },
    initializeRemove(subject) {
      this.id = parseInt(subject.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteSubject($id: Int!) {
              deleteSubject(input: { id: $id }) {
                subject {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteSubject.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.subjects.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.$route.params.school_id}/subject/new`,
      },
    ])

    this.$apollo.addSmartQuery('subjects', {
      query: SUBJECTS,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.subjects.refetch()
  },
}
</script>
